import { default as cartl5ckCViQWgMeta } from "/opt/build/repo/pages/cart.vue?macro=true";
import { default as customerjwKeaDolq0Meta } from "/opt/build/repo/pages/customer.vue?macro=true";
import { default as errorAgWXU9CPFRMeta } from "/opt/build/repo/pages/error.vue?macro=true";
import { default as paymentQ8bq6VmO0YMeta } from "/opt/build/repo/pages/payment.vue?macro=true";
import { default as shippingMKy4Gost8yMeta } from "/opt/build/repo/pages/shipping.vue?macro=true";
import { default as success5PxaWcwlslMeta } from "/opt/build/repo/pages/success.vue?macro=true";
import { default as cartG56hCyeg5uMeta } from "~/pages/cart.vue?macro=true";
export default [
  {
    name: "cart",
    path: "/cart",
    meta: cartl5ckCViQWgMeta || {},
    component: () => import("/opt/build/repo/pages/cart.vue")
  },
  {
    name: "customer",
    path: "/customer",
    component: () => import("/opt/build/repo/pages/customer.vue")
  },
  {
    name: "error",
    path: "/error",
    meta: errorAgWXU9CPFRMeta || {},
    component: () => import("/opt/build/repo/pages/error.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/opt/build/repo/pages/payment.vue")
  },
  {
    name: "shipping",
    path: "/shipping",
    component: () => import("/opt/build/repo/pages/shipping.vue")
  },
  {
    name: "success",
    path: "/success",
    meta: success5PxaWcwlslMeta || {},
    component: () => import("/opt/build/repo/pages/success.vue")
  },
  {
    name: "init",
    path: "/",
    meta: cartG56hCyeg5uMeta || {},
    component: () => import("~/pages/cart.vue")
  }
]