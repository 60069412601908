<template>
  <NuxtLayout />
</template>

<script setup>
  const runtimeConfig = useRuntimeConfig();

  useHead({
    link: [
      {
        id: 'brand-styles',
        rel: 'stylesheet',
        href: `/styles/${runtimeConfig.public.SHOP.BRAND_STYLING}-cdn-styles.css`
      }
    ]
  });
  const hasMarketingConsent = ref(false);
  const hasStatisticsConsent = ref(false);
  const hasPreferencesConsent = ref(false);

  if (process.client) {
    setTimeout(() => {
      checkCookieConsent();
    }, 3000);
  }

  function checkCookieConsent() {
    if (window.Cookiebot && window.Cookiebot.consent) {
      hasMarketingConsent.value = window.Cookiebot.consent.marketing;
      hasStatisticsConsent.value = window.Cookiebot.consent.statistics;
      hasPreferencesConsent.value = window.Cookiebot.consent.preferences;

      console.log('Cookie Consent Status:', {
        marketing: hasMarketingConsent.value,
        statistics: hasStatisticsConsent.value,
        preferences: hasPreferencesConsent.value
      });

      // You can trigger other actions based on consent here
      if (hasMarketingConsent.value) {
        // Initialize marketing-related features
      }

      if (hasStatisticsConsent.value) {
        // Initialize analytics
      }
    } else {
      console.log('Cookiebot not initialized yet');
    }
  }
</script>

<style lang="scss">
  main {
    order: 1;
    max-width: 100%;
    padding: 30px 30px 300px;

    @include min-screen('medium') {
      order: unset;
      padding: 30px 60px 60px;
      grid-area: content;
    }
  }
</style>
